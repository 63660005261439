
    .flex-box {
        &::v-deep {
            .el-input__inner {
                width: 200px;
            }
        }
    }
    .cm-contain {
        height: 100%;
    }

    .cm-contain-head {
        height: 64px;
        line-height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #EAEAEA;
    }

    .head-title {
        margin-left: 40px;
    }

    .head-botton {
        margin-right: 40px;
    }


    .cm-contain-main {
        height: calc(100% - 114px);
        overflow: auto;
    }

    .cm-contain-main span:first-child {
        cursor: pointer;
    }

    .cm-contain-main span:last-child {
        cursor: pointer;
        margin-left: 19px;
    }

    .cm-contain-bottom {
        margin-top: 10px;
        align-items: center;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
    }

    .el-pagination {
        margin: 0 auto;
    }

    /*start滚动条*/
    ::-webkit-scrollbar {
        width: 0.25rem;
        height: 0.25rem;
        background-image: linear-gradient(135deg, #1DE9B6 0%, rgba(8, 196, 219, 0.5) 72%, rgba(0, 182, 234, 0.3) 100%);
    }
    ::-webkit-scrollbar-track {
        border-radius: 0;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-image: linear-gradient(135deg, #1DE9B6 0%, #08c4db 72%, #057494 100%);
        transition: all .2s;
        border-radius: 0.25rem;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(95, 95, 95, 0.7);
    }
    /*end滚动条*/
    /*start创建班级*/
    .cm-create-dialog{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
    }
    .cm-create-dialog .create-contain {
        width: 450px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 12;
        background: #fff;
    }
    .create-head{
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid #EAEAEA;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .create-head span:first-child{
        margin-left: 32px;
    }
    .create-head span:last-child {
        margin-right: 16px;
        cursor: pointer;
    }
    /*end创建班级*/
