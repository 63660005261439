
    .avatar {
        width: 32px;
        height: 32px;
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
    }
    .upload-btn {
        position: relative;
        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .up-img-show {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border: 1px solid #ccc;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 10px;
        overflow: hidden;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .cm-contain {
        height: 100%;
    }

    .cm-contain-head {
        height: 64px;
        line-height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #EAEAEA;
    }

    .head-title {
        margin-left: 40px;
    }

    .head-botton {
        margin-right: 40px;
    }

    .head-botton .el-button {
        width: 108px;
        /*height: 36px;*/
        background-color: #005496;
        color: white;
    }

    .cm-contain-middle {
        height: 63px;
        line-height: 63px;
        display: flex;
        flex-direction: row;
    }

    .middle-title {
        margin-left: 40px;
    }

    .cm-contain-main {
        height: calc(100% - 177px);
        overflow: auto;
    }

    .cm-contain-main img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .cm-contain-main span:first-child {
        cursor: pointer;
    }

    .cm-contain-main span:last-child {
        cursor: pointer;
        margin-left: 19px;
    }

    .cm-contain-bottom {
        margin-top: 10px;
        align-items: center;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
    }

    .el-pagination {
        margin: 0 auto;
    }
    /*start滚动条*/
    ::-webkit-scrollbar {
        width: 0.25rem;
        height: 0.25rem;
        background-image: linear-gradient(135deg, #1DE9B6 0%, rgba(8, 196, 219, 0.5) 72%, rgba(0, 182, 234, 0.3) 100%);
    }
    ::-webkit-scrollbar-track {
        border-radius: 0;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-image: linear-gradient(135deg, #1DE9B6 0%, #08c4db 72%, #057494 100%);
        transition: all .2s;
        border-radius: 0.25rem;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(95, 95, 95, 0.7);
    }
    /*end滚动条*/
    /*Start创建学生弹窗 */
    .sm-create-dialog{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
    }
    .sm-create-dialog .create-contain {
        width: 450px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 12;
        background: #fff;
    }
    .create-head{
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid #EAEAEA;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .create-head span:first-child{
        margin-left: 32px;
    }
    .create-head span:last-child {
        margin-right: 16px;
        cursor: pointer;
    }
    .create-contain .el-input__inner{
        height: 30px;
        width: 334px;
    }
    .create-button{
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
    }
    /*End创建学生弹窗*/
